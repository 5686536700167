<template>
  <s-crud
    title="Material"
    :config="configVariedades"
    @save="save($event)"
    edit
    add
    remove
    :filter="filter"
    search-input
    ref="crud"
  >
    <template v-slot="props">
      <v-row justify="center">
        <v-col lg="4" class="s-col-form" cols="12"> 
          <s-select-article-category label="Artículo" v-model="ArcID">
          </s-select-article-category>
        </v-col>
      </v-row>
    </template>

    <template v-slot:SecStatus="{ row }">
      <v-chip
        style="margin:0px"
        x-small
        :color="row.SecStatus == 1 ? 'success' : 'error'"
      >
        {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>
  </s-crud>
</template>

<script>
import _sCultiveMaterial from "@/services/Technicalassistance/AsignmentCultiveMaterial";
import SSelectArticleCategory from "@/components/logistics/SSelectArticleCategory";

export default {
  components: {SSelectArticleCategory},
  props: {
    CultiveID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      dialogEdit: false,
      ArcID: 0,
    };
  },

  computed: {
    configVariedades() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          CultArcID: "ID",
          CultID: "int",
          ArcID: "int",
          //
        },
        service: _sCultiveMaterial,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "CultArcID", sortable: false },
              { text: "Artículo", value: "ArcDescription", sortable: false },
            ]
          : [
              // CABECERA EN CASO NO SE LOGEE COMO ADMIN
              { text: "ID", value: "CultArcID", sortable: false },
              { text: "Artículo", value: "ArcDescription", sortable: false },
            ],
      };
    },

    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
    },
  },

  methods: {
    Initialize() {
      //DedID: this.DedID -- base de datos: campo actual
      this.filter = { CultID: this.CultiveID };
    },

    edit(item) {
      this.dialogEdit = true;
    },

    rowSelected(rows) {
      if (rows.length > 0) {
        this.$emit("rowSelected", rows);
      }
    },

    save(item) {
      item.ArcID = this.ArcID;
      item.CultID = this.CultiveID;
      if (item.ArcID == 0) {
        this.$fun.alert("Registre el material", "warning");
        return;
      }
      if(this.validationForm(item));
      console.log(item);
      item.save();
    },

    validationForm(item){
      return true;
    }
  },
  watch: {
    // este DedID es el que esta en variety, vista general en DedID  ="dedID"
    CultiveID() {
      this.Initialize();
    },
  },
  created() {
    this.filter = { CultID: this.CultiveID };
  },
};
</script>
