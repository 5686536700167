<template>
  <div>
    <v-row>
      <v-col lg="6">
        <cultive :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100"> >
        </cultive>        
      </v-col>
      <v-col lg="6">
        <material :isAdmin="true"  :CultiveID="CultiveID" :height="200"> >
        </material> 
      </v-col>
  
      
     
    </v-row>
  </div>
</template>

<script>
import Material from "@/views/TechnicalAssistance/AsignmentCultiveMaterial/Material";
import Cultive from "@/views/TechnicalAssistance/AsignmentCultiveMaterial/Cultive";

export default {
  components: {
    Cultive, Material
  },
  data() {
    return {
         CultiveID:0,
    };
  },
   
    Cultivedata() {
    return {
      parentID: 0,
      CultiveID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
      if(rows.length>0){
        this.CultiveID=rows[0].DedID;
      }
      
    },
   
  },
};
</script>
