<template>
  <s-crud
    @returnObject="refresh($event)"
    no-border
    title="Cultivos"
    :config="this.config"
    @save="saveDed($event)"
    @clearForm="clearForm()"
    restore
    @rowSelected="rowSelected($event)"
    :filter="filter"
  >
    <template v-slot:SecStatus="{ row }">
      <v-chip
        style="margin:0px"
        x-small
        :color="row.SecStatus == 1 ? 'success' : 'error'"
      >
        {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>
  </s-crud>
</template>

<script>
import _sDefinitionDetail from "@/services/General/DefinitionDetailService";
export default {
  props: {
    defID: 0,

    defDescription: "",
  },
  data: () => ({
    dedValue: 0,
    config: {
      title: "Definiciones Detalles",
      service: _sDefinitionDetail,
      model: {
        DedID: "ID",
        DefID: "int",
        DedDescription: "string",
        DedAbbreviation: "string",
        DedHelper: "string",
        DedValue: "int",
        SecStatus: "status",
      },
      headers: [
        {
          //
          text: "ID",
          value: "DedID",
          width: "5%",
          align: "end",
          sorteable: true,
        },
        {
          text: "Definicion",
          value: "DedDescription",
          width: "55%",
          sorteable: true,
        },
        {
          text: "Abreviatura",
          value: "DedAbbreviation",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Helper",
          value: "DedHelper",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Valor",
          value: "DedValue",
          width: "5%",
          align: "center",
          sorteable: true,
        },
        {
          text: "Estado",
          value: "SecStatus",
          width: "5%",
          align: "center",
          sorteable: false,
        },
      ],
    },
  }),

  computed: {
    filter() {
      return { DefID: 1173, SecStatus: 0 };
    },
  },
  methods: {
    rowSelected(items) {
      this.$emit("rowSelected", items);
    },
  },
};
</script>
