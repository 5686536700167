import Service from "../Service";

const resource = "asignmentcultivematerial/";

export default {
    pagination(parameters, requestID) {
      return Service.post(resource + "pagination", parameters, {
        params: { requestID: requestID },
      });
    },

    save(parameters, requestID) {
      return Service.post(resource + "save", parameters, {
        params: { requestID: requestID },
      });
    },
}